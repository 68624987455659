// Compatibility fix, see: https://github.com/vitejs/vite/issues/4593
window.require = (name) => new URL(name, import.meta.url).href;

// Slick slider
import '@node_modules/slick-carousel/slick/slick.scss';
import '@node_modules/slick-carousel/slick/slick-theme.scss';

// Stylesheets
import '../scss/app.scss';

/**
 * This file is the entrypoint for the JavaScript compiler. Create new 
 * modules in the modules folder to extend the JavaScript code.
 */
import.meta.globEager('./modules/**/*.js');
