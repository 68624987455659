(function ($) {
    'use strict';

    window.WooCommerceArchive = {
        /**
         * Initialise the object.
         */
        init: function () {
            this.html = $('html');
            this.body = $('body');
            this.window = $(window);

            this.filters();
        },

        /**
         * Mobile menu functionality.
         */
        filters: function () {
            // Show filters
            $(document).on('click', '.archive .shop .show-filters', function() {
                WooCommerceArchive.showFilters();
            });

            // Hide filters
            $(document).on('click', '.archive .shop .filters .mobile-header .close', function() {
                WooCommerceArchive.hideFilters();
            });
        },

        /**
         * Hide the filters overlay.
         */
        showFilters: function() {
            $('.archive .shop .filters').show();
        },

        /**
         * Hide the filters overlay.
         */
        hideFilters: function() {
            $('.archive .shop .filters').hide();
        },
    }
})(jQuery);

WooCommerceArchive.init();
