(function ($) {
    'use strict';

    window.Menu = {
        /**
         * Initialise the object.
         */
        init: function () {
            this.html = $('html');
            this.body = $('body');
            this.window = $(window);

            this.navigation();
            this.mobileNavigation();
        },

        /**
         * Desktop menu functionality.
         */
        navigation: function() {
            // Allow closing the mega menu by clicking on it
            $('ul.menu > li.item > .link').on('click', function() {
                let inlineStyle = $(this).siblings('.mega-menu').attr('style');

                if(inlineStyle === undefined || inlineStyle === 'display: none;') {
                    $(this).siblings('.mega-menu').attr('style', 'display: block;');
                } else if($(this).siblings('.mega-menu').css('display') !== 'none') {
                    $(this).siblings('.mega-menu').attr('style', 'display: none;');
                }

                // Make sure it can be reopened by hovering after closing
                $(this).on('mouseleave', function() {
                    $(this).siblings('.mega-menu').css('display', '');
                });
            });
        },

        /**
         * Mobile menu functionality.
         */
        mobileNavigation: function () {
            // Open menu
            $('header .mobile-menu').on('click', function() {
                $('header .navigation-mobile[data-parent="0"]').css('display', 'flex');
            });

            // Go a nested menu deeper
            $('header .navigation-mobile ul.menu li.menu-item-has-children a').on('click', function(event) {
                event.preventDefault();

                let menuId = $(this).data('menu-id');
                let childMenu = 'header .navigation-mobile[data-parent=' + menuId + ']';

                $(childMenu).css('display', 'flex');
            });

            // Go a nested menu back
            $('header .navigation-mobile .back').on('click', function() {
                $(this).closest('.navigation-mobile').hide();
            });

            // Close all menus
            $('header .navigation-mobile .close').on('click', function() {
                $('header .navigation-mobile').hide();
            });
        },
    }
})(jQuery);

Menu.init();
