// Explicit jQuery import is required for Slick
import * as jQueryExternal from 'jquery';
import 'slick-carousel';
import { Collapse } from 'bootstrap';

(function ($) {
    'use strict';

    window.WooCommerceSingleProduct = {
        /**
         * Initialise the object.
         */
        init: function () {
            this.html = $('html');
            this.body = $('body');
            this.window = $(window);

            if(this.body.hasClass('single-product')) {
                this.updateVariation();
                this.setQuantity();
                this.screenWidthListener();
                this.openReviews();
                this.addedToCartModal();
            }
        },

        /**
         * Set page values when a variation is found.
         */
        updateVariation: function () {
            let self = this;
            let bulkDiscounts = $('.bulk-discount');
            let stockStatus = $('.variations_form .stock-status');

            if(bulkDiscounts.length === 0) {
                return false;
            }

            $(document).on('found_variation', function(event, variation) {
                let nf = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });
                let price = variation.display_price;
                let discountOption = bulkDiscounts.find('.option-discount').first().clone();
                let discountArray = bulkDiscounts.data('bulk-discount');
                let addToCartButton = $('.single_add_to_cart_button');

                // Set stock availability
                if(variation.is_in_stock) {
                    stockStatus.addClass('in-stock');
                    stockStatus.removeClass('out-of-stock');
                    stockStatus.text('Op voorraad');
                    addToCartButton.attr('disabled', false);
                } else {
                    stockStatus.removeClass('in-stock');
                    stockStatus.addClass('out-of-stock');
                    stockStatus.text('Uitverkocht');
                    addToCartButton.attr('disabled', true);
                }

                bulkDiscounts.find('.option-base .base-price').text(nf.format(price));
                bulkDiscounts.find('.option-discount').remove();

                for(let discount of discountArray) {
                    let newOption = discountOption.clone();
                    let newPrice = price * (100 - discount.discount) / 100;

                    newOption.find('input').attr('id', 'quantity-' + discount.quantity).val(discount.quantity);
                    newOption.find('label').attr('for', 'quantity-' + discount.quantity);
                    newOption.find('.discount-quantity').text(discount.quantity);
                    newOption.find('.discount-price').text(nf.format(newPrice));
                    newOption.find('.discount-total').text(nf.format(discount.quantity * Math.round(100 * newPrice) / 100));
                    newOption.find('.discount-percentage').text(discount.discount);
                    newOption.appendTo(bulkDiscounts);
                }

                // Show the bulk discount section
                bulkDiscounts.css('display', 'flex');
                self.setQuantity();

                // Display variation image
                let selector = 'div';
                for(let attribute in variation.attributes) {
                    selector += `[${attribute.replace('attribute_', 'data-')}="${variation.attributes[attribute]}"]`;
                }

                let images = $(selector);
                if(images) {
                    images[images.length > 2 ? 1 : 0].closest('.slick-slide').click();
                }
            });

            $(document).on('reset_data', function() {
                let defaultStock = stockStatus.data('default-stock');

                bulkDiscounts.hide();
                stockStatus.removeClass('in-stock');
                stockStatus.removeClass('out-of-stock');
                stockStatus.addClass(defaultStock);
                stockStatus.text(defaultStock === 'in-stock' ? 'Op voorraad' : 'Uitverkocht');
            });
        },

        /**
         * Sets the quantity when a bulk discount is selected.
         */
        setQuantity: function () {
            let bulkDiscounts = $('.bulk-discount');
            let radio = bulkDiscounts.find('input[type="radio"]');

            radio.on('click', function() {
                let quantity = $(this).val();
                let quantityInput = $('.quantity input.qty');

                quantityInput.val(quantity);
            });
        },

        /**
         * Collapse the right sections based on screen size.
         */
        screenWidthListener: function() {
            let currentSize = $(window).width();

            // Collapse if window width is smaller on initial load (currently disabled)
            if(currentSize < 576 && false) {
                WooCommerceSingleProduct.collapseSections();
            }

            // Check window size when resizing
            $(window).on('resize', function() {
                currentSize = $(window).width();

                if(currentSize < 576 && false) {
                    WooCommerceSingleProduct.collapseSections();
                }
            });
        },

        /**
         * Collapses all sections.
         */
        collapseSections: function() {
            $('.description .collapse-section .collapse').each(function(index, element) {
                // Hide all elements
                Collapse.getOrCreateInstance(element).hide();

                // Add event listener so that Slick will get refreshed when opened again
                element.addEventListener('show.bs.collapse', event => {
                    (function($) {
                        $('.slick-initialized').slick('refresh');
                    })(jQueryExternal);
                })
            });
        },

        /**
         * Open reviews section when clicked in summary.
         */
        openReviews: function() {
            $('.woocommerce-product-rating').on('click', function() {
                // Scroll to reviews
                $('html, body').animate({
                    scrollTop: $("#reviews").offset().top - 80
                }, function() {
                    // Open reviews
                    $('#product-reviews').collapse('show');
                });
            });
        },

        /**
         * Modal to display after adding a product to the cart.
         */
        addedToCartModal: function() {
            const { __ } = wp.i18n;

            let addToCart = $('button[name="add-to-cart"]');
            let modal = $('.modal-added-to-cart');
            let continueShopping = $('.continue-shopping');

            addToCart.on('click', function() {
                $(this).addClass('loading').text(__('Toevoegen...', 'blayce'));
            });

            modal.on('click', function(e) {
                if(e.target === this) {
                    modal.hide();
                }
            });

            continueShopping.on('click', function() {
                modal.hide();
            });
        },
    }
})(jQuery);

WooCommerceSingleProduct.init();