(function ($) {
    'use strict';

    window.Header = {
        /**
         * Initialise the object.
         */
        init: function () {
            this.html = $('html');
            this.body = $('body');
            this.window = $(window);

            this.stickyAddToCart();
        },

        /**
         * Sticky add to cart header functionality.
         */
        stickyAddToCart: function() {
            // Wait until jQuery is loaded, equivalent to $(document).ready();
            jQuery(function() {
                let addToCart = $('.product-information .single_add_to_cart_button');
                let addToCartPositionBottom = null;

                if(addToCart.length > 0) {
                    addToCartPositionBottom = addToCart.offset().top + addToCart.outerHeight();
                    $('body #header .product-bar').toggleClass('visible', window.scrollY > addToCartPositionBottom - 100);
                }
    
                $(window).on('scroll', function() {
                    if(addToCart.length > 0) {
                        $('body #header .product-bar').toggleClass('visible', window.scrollY > addToCartPositionBottom - 100);
                    }
                });
            });
        },
    }
})(jQuery);

Header.init();
