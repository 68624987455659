// Explicit jQuery import is required for Slick
import jQuery from 'jquery';
import 'slick-carousel';

(function ($) {
    'use strict';

    window.SlickCarousel = {
        /**
         * Initialise the object.
         */
        init: function () {
            this.html = $('html');
            this.body = $('body');
            this.window = $(window);

            this.initSlick();
            this.woocommerceProductGallery();
        },

        /**
         * Initialise global Slick functionality.
         */
        initSlick: function () {
            // General sliders
            $('.slick-carousel-init:not(.slick-initialized)').slick({
                "swipeToSlide": true,
                "responsive": [
                    {
                        "breakpoint": 992, 
                        "settings": {
                            "slidesToShow": 2
                        }
                    }
                ]
            });

            // Cart cross sells slider
            $('.slick-carousel-cart-init:not(.slick-initialized)').slick({
                "swipeToSlide": true,
                "responsive": [
                    {
                        "breakpoint": 1200, 
                        "settings": {
                            "slidesToShow": 3
                        }
                    },
                    {
                        "breakpoint": 992, 
                        "settings": {
                            "slidesToShow": 2
                        }
                    },
                ]
            });

            // Slider after adding a product to the cart
            $('.slick-carousel-added-init:not(.slick-initialized)').slick({
                "swipeToSlide": true,
                "responsive": [
                    {
                        "breakpoint": 992, 
                        "settings": {
                            "slidesToShow": 4
                        }
                    },
                    {
                        "breakpoint": 768, 
                        "settings": {
                            "slidesToShow": 2
                        }
                    },
                ]
            });
            
            // Trigger window resize to force correct breakpoint
            $(window).trigger('resize');
        },

        /**
         * Apply Slick to the product thumbnails.
         */
        woocommerceProductGallery: function() {
            let main = $('.woocommerce-product-gallery .main-images');
            let thumbnails = $('.woocommerce-product-gallery .thumbnails');

            // Check if WooCommerce is done loading the photo gallery, else execute function again
            if(thumbnails.length > 0) {
                main.slick({
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    asNavFor: '.woocommerce-product-gallery .thumbnails'
                });
                
                thumbnails.slick({
                    arrows: true, 
                    asNavFor: '.woocommerce-product-gallery .main-images',
                    slidesToShow: 4, 
                    slidesToScroll: 1,
                    focusOnSelect: true
                });
            } else {
                setTimeout(this.woocommerceProductGallery, 300);
            }
        }
    }
})(jQuery);

SlickCarousel.init();
